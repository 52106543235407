.faq {
  border: 0px solid rgba(0, 0, 0, 0.125);
  box-shadow: "none";
  padding: 40px 10px 40px 10px;
}

#cart {
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 200;
  text-align: center;
}

.animation {
  position: relative;
  top: 30px;
}

.image-responsive {
  overflow: hidden;
  position: relative;
  padding: 30px 10px 30px 10px;
}

.product-card {
  width: 100%;
  border: 1px solid silver;
  display: flex;
  flex-direction: column;
  padding: 20px 8px 4px 8px;
  align-items: center;
  height: 100%;
  background-color: #fff;
}

.product-name {
  font-size: 16pt;
  font-weight: 500;
}

.product-sku {
  font-size: 8pt;
}

.product-description {
  font-size: 12pt;
}

.retail-price {
  font-size: 12pt;
  color: blue;
  text-decoration: line-through;
}

.promo-price {
  color: rgb(233, 56, 142);
  font-size: 12pt;
  font-weight: 400;
}

.product-choice {
  font-size: 11pt;
  font-weight: 200;
}

.product-quantity {
  color: purple;
  font-size: 12pt;
  font-weight: 300;
}

.forsale-sold {
  color: red;
}
.forsale-available {
  color: green; 
}

.order-card {
  width: 100%;
  border: 2px solid orange;
  display: flex;
  flex-direction: column;
  padding: 20px 8px 4px 8px;
  margin: 20px 8px 4px 8px;
  align-items: center;
  height: auto;
  background-color: #d1d110;
}