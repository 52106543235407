.description {
  display: inherit;
  justify-content: inherit;
  align-items: inherit;
  font-size: 1rem;
  max-width: var(--max-width);
  width: 100%;
  z-index: 2;
  font-family: var(--font-mono);
  padding: 1rem;
}

.description a {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.description p {
  position: relative;
  margin: 0;
  padding: 1rem;
  background-color: rgba(var(--callout-rgb), 0.9);
  border: 1px solid rgba(var(--callout-border-rgb), 0.3);
  border-radius: var(--border-radius);
}

.code {
  font-weight: 700;
  font-family: var(--font-mono);
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(50%, auto));
  max-width: 100%;
  width: var(--max-width);
  text-align: center;
}

.card {
  padding: 1rem 1.2rem;
  margin: 1rem;
  border-radius: var(--border-radius);
  background: rgba(var(--card-rgb), 1);
  /** background: #eee; **/
  border: 1px solid rgba(var(--card-border-rgb), 0.1);
  transition: background 200ms, border 200ms;
  text-align: left;
  font-weight: 300;
  color: #333;
}

.card b {
  font-weight: 400;
}

.card span {
  display: inline-block;
  transition: transform 200ms;
}

.card h2 {
  font-weight: 400;
  margin-bottom: 0.7rem;
}

.card p {
  margin: 0;
  opacity: 0.6;
  font-size: 1rem;
  line-height: 1.5;
  max-width: 30ch;
  margin-bottom: 0.7rem;
}

.center {
  border: 0px solid red;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 1rem 0;
  text-align: center;
}

.center::before {
  background: var(--secondary-glow);
  border-radius: 50%;
  width: 480px;
  height: 80px;
  margin-left: -400px;
}

.center::after {
  background: var(--primary-glow);
  width: 240px;
  height: 180px;
  z-index: -1;
}

.center::before,
.center::after {
  left: 50%;
  position: absolute;
  filter: blur(45px);
  transform: translateZ(0);
}

/* Enable hover only on non-touch devices */
@media (hover: hover) and (pointer: fine) {
  .card:hover {
    /** background: rgba(var(--card-rgb), 0); **/
    /** background: #efe; **/
    border: 1px solid rgba(var(--card-border-rgb), 0.15);
  }

  .card:hover span {
    transform: translateX(4px);
  }
}

@media (prefers-reduced-motion) {
  .card:hover span {
    transform: none;
  }
}

/* Mobile */
@media (max-width: 700px) {
  .main {
    padding: 0rem 0 6rem 0;
  }
  .content {
    padding: 4rem;
  }

  .grid {
    grid-template-columns: 1fr;
    margin-bottom: 120px;
    max-width: 560px;
    text-align: center;
  }

  .card {
    padding: 1rem 2.5rem;
    margin: 1rem;
  }

  .card h2 {
    margin-bottom: 0.5rem;
  }

  .center {
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .center::before {
    transform: none;
    height: 300px;
  }

  .description {
    font-size: 1rem;
    z-index: 0;
  }

  .description a {
    padding: 1rem;
  }

  .description p,
  .description div {
    display: flex;
    justify-content: center;
    position: fixed;
    width: 100%;
  }

  .description p {
    align-items: center;
    inset: 0 0 auto;
    padding: 2rem 1rem 1.4rem;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid rgba(var(--callout-border-rgb), 0.25);
  }

  .description div {
    align-items: flex-end;
    /** pointer-events: none; **/
    inset: auto 0 0;
    padding: 0rem 0 2rem 0;
    background-color: rgba(var(--callout-rgb), 0.6);
    border: 1px solid rgba(var(--callout-border-rgb), 0.3);
    /**
    background: linear-gradient(
      to bottom,
      transparent 0%,
      rgb(var(--background-end-rgb)) 40%
    );
    **/
    z-index: 1;
  }

  .content {
    padding: 1rem 0 0rem 0rem;
    margin: 0;
    width: 100vw;

  }
 
}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {
  .grid {
    grid-template-columns: repeat(1, 100%);
    text-align: center;
    align-items: center;
    padding: 2rem;
  }
}

@keyframes rotate {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
