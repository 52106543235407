@use "../../theme/base";


/** override default style for MobileStepper **/
div.MuiMobileStepper-dot {
  background-color: base.$primary-dark !important;
}
div.MuiMobileStepper-dotActive {
  background-color: base.$secondary-light !important;
}
div.MuiMobileStepper-root {
  background-color: base.$primary-light !important;
}

.carousel-wrapper {

  width: 100vw;

}

.carousel {
  clear: both;
  display: flex;
  flex-direction: row;
  position:"relative"; 
  align-content: "center";
  text-align: "center";
  padding: 0; 
  margin: 0; 
  width: 100vw;
  overflow: "hidden";
}

.carousel .center {
  position: "relative";
  border: 0px solid silver; 
  display: flex;
  flex-direction: row;
  background-image: "linear-gradient(to right, rgba(255,255,255,0), rgba(225,225,225,1), rgba(255,255,255,0))";
}


.carousel .stepper {

  display: flex;
  flex-wrap: wrap;
  position:relative;
  width: 100%;
  max-width: 500px;
  text-align: center;
  align-items: center;
}