@use "../../theme/base";

.form {
  width: 90%; // so the form multiline input shows appropriately
  padding: 40px 20px 40px 20px;
  background-color: white;
  color: base.$primary-dark;
  display: flex;
  flex-direction: column;

  text-align: left;
  margin: 0px 0 0 0;
}

.pop-wrapper {
  width: 100vw;
  height: 100vh;
  z-index: 100;
  position: fixed;
  top: 0%;
  left: 0%;
  text-align: center;
  background-color: #fff;
  line-height: 1.5rem;
  overflow-y: auto;
}

.pop-wrapper-content {
  position: relative;
  top: 180px;
  background-color: #fff;
  padding: 30px;
  color: black;
  border-radius: 12px;
}

.sms-verifier-wrapper {
  width: 100vw;
  height: 100vh;
  z-index: 100;
  position: fixed;
  top: 0%;
  left: 0%;
  text-align: center;
  background-color: #fff;
  line-height: 1.5rem;
  overflow-y: auto;
  border: 1px solid red; 
  justify-items: center;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.sms-verifier-wrapper h2 {
  padding: 1.5rem 0 1.5rem 0;
  text-align: center;
  color: black;
}

.sms-verifier {
  position: relative;
  top: 20%;
  bottom: 20%;
  height: auto;
  background-color: #fff;
  padding: 30px;
  color: black;
  border-radius: 12px;
}

/** this overrides the default style for react phone number input **/
.PhoneInputInput {
  /* The phone number input stretches to fill all empty space */
  flex: 1;
  /* The phone number input should shrink
	   to make room for the extension input */
  min-width: 0;
  height: 40px;
  border-radius: 10px;
  border: 1px solid silver;
  font-size: 1.2rem;
  font-weight: 200;
  color: #666;
  padding: 10px;
}
